@import "assets/theme/colors.scss";
@import "assets/theme/typography.scss";

.small-modal {
    .delete-block {
        height          : 18px;
        border-radius   : 5px;
        background-color: rgba(213, 0, 0, 0.1);
        display         : flex;
        justify-content : center;
        align-items     : center;
        padding         : 8px 10px;
        margin-right    : 10px;
    }


    p {
        color         : #333333;
        font-family   : $sans-bold;
        font-size     : 14px;
        font-weight   : bold;
        letter-spacing: 0;
        line-height   : 20px;
    }
}

// Contact Edit Form Styles

.large-modal {
    .modal-header {
        display        : flex;
        justify-content: space-between;

        .header-icon-panel {
            height          : 35px;
            width           : 35px;
            border          : 1px solid #000000;
            border-radius   : 4px;
            background-color: #000000;
            display         : flex;
            align-items     : center;
            justify-content : center;
        }

        .modal-header-title {
            margin-left   : 15px;
            color         : #FFFFFF;
            font-family   : $sans-bold;
            font-size     : 16px;
            font-weight   : bold;
            letter-spacing: 1.07px;
            line-height   : 14px;
            text-transform: uppercase;
            margin-top: 0;
        }
    }

    .modal-body {
        .edit-contact-form {
            display       : flex;
            flex-direction: column;
            margin        : 4px 100px 10px 75px;
        }

    }

}

.cms-field-block-with-numbers {
    display      : flex;
    margin-bottom: 20px;

    .position-number {
        color         : #B7B7B7;
        font-family   : $sans-bold;
        font-size     : 20px;
        font-weight   : bold;
        letter-spacing: 2.14px;
        line-height   : 22px;
        width         : 35px;
        text-align    : right;
        margin-right  : 5px;
    }

    // Form Fields Styles
    .cms-form-group {
        display       : flex;
        flex-direction: column;
        width         : 100%;

        .cms-form-label {
            color         : #333333;
            font-family   : $sans-bold;
            font-size     : 14px;
            font-weight   : bold;
            letter-spacing: 0;
            line-height   : 24px;
        }

        .cms-form-field {
            border          : 1px solid #B7B7B7;
            border-radius   : 4px;
            background-color: #FFFFFF;
            padding         : 14px;

            &:disabled {
                background-color: rgba(239, 239, 239, 0.8) !important;
                border-color    : rgba(118, 118, 118, 0.5) !important;
                cursor          : not-allowed;
            }
        }

        // Custom Styles for Input Group

        .cms-input-group {
            position: relative;

            .floating-icon {
                position: absolute;
                top     : 10px;
                left    : 10px;
            }

            .cms-form-field {
                width       : -webkit-fill-available;
                width       : -moz-available;
                padding-left: 2.5rem;
            }
        }

        // Ends

        // Styles For Select Field

        .cms-select-field {
            position: relative;

            select {
                -webkit-appearance: none;
                appearance        : none;
                width             : 100%;
            }

            &::after {
                content  : "▼";
                font-size: .8rem;
                top      : 18px;
                right    : 15px;
                position : absolute;
            }
        }

        // Ends
    }

    // Ends
}

// Ends