.ReactModal__Overlay--after-open {
    z-index         : 99999;
    background-color: rgba(0, 0, 0, 0.5) !important;
    overflow: auto;
}

.cms-react-modal {
    border-radius   : 7px;
    background-color: #FFFFFF;
    box-shadow      : 0 0 20px 0 rgba(0, 0, 0, 0.5);
    position        : absolute;

    &.small {
        height   : 107px;
        width    : 390px;
        top      : 50%;
        transform: translateY(-50%) translateX(-50%);
        left     : 50%;
        padding  : 20px 15px;
    }

    &.large {
        width       : 946px;
        margin-top  : 73px;
        margin-left : auto;
        margin-right: auto;
        left        : 0;
        right       : 0;
        overflow    : auto;
        background  : transparent;
        box-shadow  : unset;
        padding-bottom: 73px;

        .large-modal {
            .modal-header {
                border-radius   : 13px 13px 0 0;
                background-color: #333333;
                padding         : 13px 20px;
            }

            .modal-body {
                padding         : 20px 15px;
                background-color: #FFFFFF;
                min-height      : 300px;
            }

            .modal-footer {
                background-color: #333333;
                padding: 13px 22px 12px 22px;
                align-self: flex-end;
        
                .cms-button-group {
                    padding-top: 0;
                    justify-content: flex-end;
                }

                .cms-default-btn {
                    &:hover {
                        background-color: transparent;
                        border: 1px solid #FFFFFF;
                    }
                }
            }
        }
    }
}