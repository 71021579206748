$color-green: #068255;
$blueish-green: #3ead93;

$black     : #000000;
$dull-black: #333333;

$white     : #ffffff;
$off-white: #fafafa;
$greyish-white: #e6e6e6;

$dark-grey: #707070;
$whitish-grey: #b7b7b7;
$light-grey: #f2f2f2;