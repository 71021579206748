@import "assets/theme/typography.scss";
@import "assets/theme/colors.scss";

.cms-tabs {
    .react-tabs {
        &__tab-list {
            display: flex;
        }

        &__tab {
            padding        : 0;
            border         : none;
            line-height    : 26px;
            width          : 176px;
            text-align     : center;
            height         : 84px;
            display        : flex;
            justify-content: center;
            align-items    : center;
            font-family    : $sans-bold;
            color          : $dull-black;

            &:focus {
                box-shadow  : none;
                border-color: $color-green;

                &:after {
                    position: static;
                }
            }
        }

        &__tab--selected {
            border       : none;
            border-bottom: 4px solid $color-green;
            background   : transparent;
        }
    }
}