@import "assets/theme/typography.scss";
@import "assets/theme/colors.scss";

.contactCard {
    border: 1px solid $whitish-grey;
    border-radius: 7px;
    background-color: $white;
    padding: 8px;
    display: flex;
    width: 260px;
    height: 117px;
    box-sizing: border-box;
    margin-right: 25px;
    margin-bottom: 25px;
    &__profile-block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-right: 7px;
    }
    &__profile-img {
        height: 62px;
        width: 62px;
    }
    &__profile-email {
        width: 16px;
        height: 12px;
        cursor: pointer;
        padding-bottom: 1px;
    }
    &__details {
        color: $dull-black;
        font-family: $sans-reg;
        letter-spacing: 0;
        font-size: 12px;
    }
    &__name {
        font-family: $sans-bold;
        font-size: 14px;
        padding-bottom: 3px;
    }
    &__region {
        padding-top: 2px;
    }
    &__designation {
        padding-top: 2px;
    }
    &__container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__location {
        display: flex;
        align-items: center;
    }
    &__location-icon {
        width: 12px;
        height: 17px;
        padding-right: 4px;
    }
    &__office-location {
        color: $dull-black;
        font-family: $sans-reg;
        font-size: 12px;
    }
}