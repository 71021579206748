@import "colors.scss";
@import "assets/theme/typography.scss";

html {
  scroll-behavior: smooth;
  // font-size: 51%;
}

// @media only screen and (max-width: 1024px) {
//   html {
//     font-size: 56%;
//   }
// }
// @media only screen and (min-width: 1918px) and (max-width: 1999px) {
//   html {
//     font-size: 75%;
//   }
// }
// @media only screen and (min-width: 2000px) {
//   html {
//     font-size: 100%;
//   }
// }

body {
  margin                 : 0;
  // font-family: latoRegular;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}


p {
  margin: 0px;
}

.disabled {
  cursor        : not-allowed;
  pointer-events: none;
  opacity       : 0.5;
}

.d-flex-only {
  display: flex;
}

.d-flex {
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.d-flex-align {
  display    : flex;
  align-items: center;
}

.d-flex-justify {
  display        : flex;
  justify-content: center;
}

.align-self-center {
  align-self: center;
}

.justify-content-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.vh-100 {
  height: 100vh;
}

.hw-100 {
  width: 100vw;
}

.mr20 {
  margin-right: 20px;
}

.reverse {
  transform: rotateZ(180deg);
}

// Buttons
.button-black {
  height          : 34px;
  min-width       : 142px;
  border-radius   : 5px;
  background-color: $dull-black;
  border          : none;
  color           : $off-white;
  font-family     : $sans-bold;
  font-size       : 12px;
  font-weight     : bold;
  letter-spacing  : 0.8px;
  line-height     : 14px;
  cursor          : pointer;
  padding         : 0 10px;

  span {
    margin-left: 9px;
  }

  &:hover {
    background-color: #000000;
  }
}

.button-black-outline {
  height        : 35px;
  width         : 104px;
  border        : 2px solid #333333;
  border-radius : 5px;
  color         : #333333;
  font-family   : $sans-bold;
  font-size     : 12px;
  font-weight   : bold;
  letter-spacing: 0.8px;
  line-height   : 20px;
  text-align    : center;
  padding       : 8px 25px;
  cursor        : pointer;
}

.cms-button-group {
  display        : flex;
  justify-content: center;
  align-items    : center;
  padding-top    : 20px;
}

.cms-default-btn {
  border          : none;
  background-color: #FFFFFF;
  margin          : 0 10px;
  height          : 35px;
  width           : 101px;
  border          : 2px solid #333333;
  border-radius   : 5px;
  color           : #333333;
  font-family     : $sans-bold;
  font-size       : 12px;
  font-weight     : bold;
  letter-spacing  : 0.8px;
  line-height     : 20px;
  text-align      : center;
  text-transform  : uppercase;
  cursor          : pointer;

  &:hover {
    background-color: #333333;
    color           : #FFFFFF;
  }
}

.cms-danger-btn {
  border          : none;
  height          : 35px;
  width           : 101px;
  border-radius   : 5px;
  background-color: #D50000;
  color           : #fff;
  text-transform  : uppercase;
  margin          : 0 10px;
  color           : #FFFFFF;
  font-family     : $sans-bold;
  font-size       : 12px;
  font-weight     : bold;
  letter-spacing  : 0.8px;
  line-height     : 20px;
  text-align      : center;
  cursor          : pointer;

  &:hover {
    background-color: #a63b3b;
  }
}

.cms-primary-btn {
  border          : none;
  height          : 35px;
  width           : 101px;
  border-radius   : 5px;
  background-color: #3FAD93;
  color           : #fff;
  text-transform  : uppercase;
  margin          : 0 10px;
  color           : #FFFFFF;
  font-family     : $sans-bold;
  font-size       : 12px;
  font-weight     : bold;
  letter-spacing  : 0.8px;
  line-height     : 20px;
  text-align      : center;
  cursor          : pointer;

  &:hover {
    background-color: #1f856d;
  }
}

.cms-close-icon {
  background-color: transparent;
  border          : none;
  cursor          : pointer;
  color           : #FFFFFF;
  font-size       : 24px;
  letter-spacing  : 0;
  line-height     : 20px;
}

// Custom BCG Brand Checkbox Styles

.cmsCustomCheckbox {
  -webkit-user-select: none;
  -moz-user-select   : none;
  -ms-user-select    : none;
  user-select        : none;
  position           : relative;
  cursor             : pointer;

  input {
    opacity: 0;
    cursor : pointer;
  }

  .checkmark {
    position     : absolute;
    top          : 0;
    left         : 0;
    height       : 16px;
    width        : 16px;
    border       : 1px solid #B7B7B7;
    border-radius: 3px;
  }

  &:hover input~.checkmark {
    background-color: #ccc;
  }

  input:checked~.checkmark {
    background-color: #177B57;
    border          : none;
    width           : 18px;
    height          : 18px;
  }

  .checkmark:after {
    content : "";
    position: absolute;
    display : none;
  }

  input:checked~.checkmark:after {
    display: block;
  }

  .checkmark:after {
    left             : 6px;
    top              : 2px;
    width            : 4px;
    height           : 9px;
    border           : solid white;
    border-width     : 0 2px 2px 0px;
    -webkit-transform: rotate(45deg);
    -ms-transform    : rotate(45deg);
    transform        : rotate(45deg);
  }

}

// Ends

// Pagination
.pagination {
  display        : flex;
  align-items    : center;
  justify-content: center;
  padding-bottom : 2rem;

  li {
    list-style: none;
    padding   : 0 10px;
    cursor    : pointer;

    a {
      color      : #707070;
      cursor     : pointer;
      outline    : none;
      font-family: $sans-reg;

      &:hover {
        font-weight: bolder;
      }
    }

    &.next {
      height          : 40px;
      width           : 35px;
      border-radius   : 5px;
      background-color: #3fad93;
      display        : flex;
      justify-content: center;
      align-items    : center;
      font-weight    : normal;
      a {
        color           : #fff;
      }
    }

    &.previous {
      height          : 40px;
      width           : 35px;
      border-radius   : 5px;
      background-color: #3fad93;
      display         : flex;
      justify-content : center;
      align-items     : center;
      font-weight     : normal;
      a {
        color           : #fff;
      }

      &.disabled {
        background-color: transparent;
      }
    }

    &.active {
      a {
        color      : #000000;
        font-weight: bold;
        cursor     : pointer;
        outline    : none;
        font-family: $sans-bold;
      }
    }
  }
}