@import "assets/theme/typography.scss";
@import "assets/theme/colors.scss";


.CMSRole-structure-container {
    display        : flex;
    justify-content: space-between;
    align-items    : center;

    .download-btn {
        margin-left : 22px;
        width       : 15px;
        cursor      : pointer;
        margin-right: 35px;
    }

    .edit-btn {
        width      : 15px;
        margin-left: 40px;
        cursor     : pointer;

        &.disabled {
            pointer-events: none;
            cursor: not-allowed;
        }
    }

    .button-black {
        margin-left: 10px;
    }

    .button-black-outline {
        margin-left: 10px;
    }

    .button-naro {
        background-color: #F2F2F2;
        color           : #333333;
        font-family     : $sans-reg;
        font-size       : 14px;
        letter-spacing  : 0;
        line-height     : 24px;
        border-radius   : 20px;
        padding         : 4px 16px;
        border          : none;
        margin          : 0 8px;
        cursor: pointer;

        &.active {
            background-color: #333333;
            color           : #FFFFFF;
        }
    }
}

.CMSRolePages-container {
    background-color: #FFFFFF;
    display         : flex;
    flex-direction  : column;

    .CMSRolePages-header {
        width      : 100%;
        height     : 50px;
        display    : flex;
        align-items: center;
        box-shadow : 0 2px 0 0 #F2F2F2;

        .CMSRolePages-header-item {
            width         : 320px;
            color         : #707070;
            font-family   : $sans-reg;
            font-size     : 12px;
            letter-spacing: 0;
            line-height   : 17px;

            .multiselect__txt {
                justify-content: center;
            }
        }
    }

    .CMSRolePages-list-group {
        display    : flex;
        margin-top: 5px;

        .CMSRolePages-list-group-items {
            width: 300px;
            padding-left: 17px;
            border-right: 3px solid #F2F2F2;

            .CMSRolePages-list-group-item {
                padding       : 15px 92px 11px 0;
                color         : #333333;
                font-family   : $sans-reg;
                font-size     : 12px;
                letter-spacing: 0;
                line-height   : 14px;
                cursor: move;
            }
        }
    }
}