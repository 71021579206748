@import "assets/theme/typography.scss";
@import "assets/theme/colors.scss";

.access-management {
    width: 100%;

    &__heading {
        color         : $dull-black;
        font-family   : $sans-bold;
        font-size     : 14px;
        letter-spacing: 0;
        line-height   : 24px;
        padding-left  : 22px;
        padding-bottom: 20px;
    }

    &__pills {
        margin-bottom: 20px;

        &__button {
            border-radius   : 20px;
            height          : 32px;
            padding         : 4px 16px;
            background-color: #F2F2F2;
            color           : #333333;
            font-family     : $sans-reg;
            font-size       : 14px;
            letter-spacing  : 0;
            line-height     : 24px;
            margin-right    : 20px;
            border          : none;
            cursor          : pointer;

            &:hover {
                background-color: #B7B7B7;
            }
        }

        &__active {
            background-color: #333333;
            color           : #FFFFFF;
            cursor          : default;

            &:hover {
                background-color: #333333;
            }
        }
    }

    &__contacts-list {
        display        : flex;
        flex-wrap      : wrap;
        justify-content: center;
    }

    &__admin_list {
        display        : flex;
        flex-wrap      : wrap;
        justify-content: start;
    }

    &__logs-list {
        display       : flex;
        flex-direction: column;
        width         : 100%;

        p {
            color         : #333333;
            font-family   : $sans-reg;
            font-size     : 12px;
            letter-spacing: 0;
            line-height   : 14px;
            padding       : 18px 22px;

            &:nth-child(odd) {
                background-color: #FFFFFF;
            }
        }
    }

    &__logs-list-link {
        color           : #177B57;
        font-family     : $sans-reg;
        font-size       : 12px;
        letter-spacing  : 0;
        line-height     : 14px;
        border          : none;
        background-color: transparent;
        cursor          : pointer;
        text-decoration : underline;

        &:hover {
            font-weight: bold;
        }
    }
}

.detail-modal {
    .cms-react-modal {
        width    : 390px;
        top      : 50%;
        transform: translateY(-50%) translateX(-50%);
        left     : 50%;
        padding  : 20px 15px 0 15px;

        .cms-close-icon {
            background-color: #333333;
            position        : absolute;
            right           : 10px;
            top             : 5px;
            border-radius   : 100%;
            font-size       : 18px;
            width           : 25px;
            height          : 25px;
            display         : flex;
            justify-content : center;
            align-items     : center;
        }

        .log-details {
            display        : flex;
            justify-content: space-between;
            margin-top     : 1.2rem;

            .log-detail-item {
                display       : flex;
                flex-direction: column;

                &:last-child {
                    margin-left: 12px;
                }

                span {
                    color        : #777777;
                    margin-bottom: 5px;
                    font-family: $sans-reg;
                }

                p {
                    margin-bottom: 5px;
                    font-family: $sans-bold;
                }
            }
        }
    }
}