@import "assets/theme/typography.scss";
@import "assets/theme/colors.scss";

.contacts {
    &__initial {
        background-color: $white;
        padding         : 40px 0 140px;

        p {
            height        : 73px;
            color         : #B7B7B7;
            font-family   : $sans-bold;
            font-size     : 18px;
            font-weight   : bold;
            letter-spacing: 0;
            line-height   : 22px;
            text-align    : center;
            padding       : 0px 110px;
        }
    }

    &__initial-block {
        width: 642px;
    }

    &__container {
        width              : 100%;
        // background-color: #ffffff;



        .contacts-filter-actions {
            margin-bottom: 17px;
            position     : relative;

            .filter-groups {
                display    : flex;
                align-items: center;
                margin-left: 8px;

                .checkbox {
                    vertical-align: middle;
                    width         : 16px;
                    height        : 16px;
                }

                .filterIcon {
                    margin: 0 6px 0 10px;
                }
            }

            .filter-by-text {
                color         : #707070;
                font-family   : $sans-bold;
                font-size     : 12px;
                font-weight   : bold;
                letter-spacing: 0.8px;
                line-height   : 20px;
                display       : flex;
                align-items   : center;

                i {
                    margin-left : 10px;
                    margin-right: 6px;
                }
            }

            .v-divider {
                margin: 0 10px 0 10px;
            }

            .dropdown-btn {
                color         : #333333;
                font-family   : $sans-bold;
                font-size     : 12px;
                font-weight   : bold;
                letter-spacing: 0.8px;
                line-height   : 20px;
                text-align    : right;
                margin-right  : 32px;
                cursor        : pointer;

                span {
                    margin-right: 11px;
                }
            }

            .delete-btn {
                margin-right: 20px;
                width       : 15px;
                cursor      : pointer;
            }

            .download-btn {
                margin-right: 20px;
                width       : 15px;
                cursor      : pointer;
            }
        }

        .contacts-result {
            width        : 100%;
            overflow-y   : scroll;
            height       : 750px;
            margin-bottom: 2rem;
            overflow-x   : hidden;

            &.scroll-bar {
                scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3) !important;
                scrollbar-width: thin !important;
            }

            &.scroll-bar::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius     : 10px;
                display           : none;
            }

            &.scroll-bar::-webkit-scrollbar {
                width: 3px;
            }

            &.scroll-bar::-webkit-scrollbar-thumb {
                border-radius     : 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                background-color  : #555;
                padding-top       : 12rem;
                scroll-padding    : 107px 0px 0px 100px;
            }

            .cms-table-root {
                width          : 100%;
                border         : 1px solid #f2f2f2;
                border-top     : 0;
                border-collapse: collapse;
                border-collapse: collapse;

                .cms-table-head {
                    .stickyHeader {
                        top             : 0;
                        left            : 0;
                        z-index         : 2;
                        position        : sticky;
                        background-color: #FFFFFF
                            /*Add this only on scroll started and check scroll position to stop and remove this property */
                    }

                    .cms-table-row {
                        color           : inherit;
                        outline         : 0;
                        background-color: #FFFFFF;
                        position        : relative;
                        z-index         : 999;

                        .cms-table-cell {
                            &.cms-table-cell-head {
                                padding       : 14px 10px 10px;
                                color         : #707070;
                                line-height   : 14px;
                                font-family   : $sans-reg;
                                font-size     : 12px;
                                letter-spacing: 0;
                                font-weight   : normal;
                                text-align    : left;
                            }
                        }
                    }
                }


                .cms-table-body {
                    .cms-table-row {
                        transition      : transform .2s;
                        color           : inherit;
                        outline         : 0;
                        background-color: #FFFFFF;
                        vertical-align  : unset;
                        position        : relative;

                        &:nth-child(odd) {
                            background-color: #f2f2f2;
                        }

                        &:hover {
                            transform       : scale(1.0059);
                            position        : relative;
                            z-index         : 9;
                            border          : 1px solid #F2F2F2;
                            border-radius   : 1px;
                            background-color: #F2F2F2;
                            box-shadow      : 0 0 10px 0 rgba(0, 0, 0, 0.2);
                        }

                        .cms-table-cell {
                            color         : #333333;
                            font-family   : $sans-reg;
                            font-size     : 12px;
                            letter-spacing: 0;
                            line-height   : 14px;
                            padding       : 18px 10px;

                            &.check-field {
                                padding    : 18px 10px;
                                cursor     : pointer;
                                white-space: nowrap;
                                white-space: -webkit-nowrap;

                                .checkbox {
                                    margin        : 0 9px 0 0;
                                    vertical-align: middle;
                                    width         : 16px;
                                    height        : 16px;
                                }

                                .checkboxName {
                                    // white-space  : nowrap;
                                    // width     : 50px;
                                    // overflow     : hidden;
                                    // text-overflow: ellipsis;
                                }
                            }
                        }

                        .cms-contact-button-group-floated {
                            position       : absolute;
                            right          : 15px;
                            display        : flex;
                            align-items    : center;
                            justify-content: center;
                            height         : 100%;

                            button {
                                cursor          : pointer;
                                margin-right    : 10px;
                                background-color: #FFFFFF;
                                color           : $color-green;
                                border          : none;
                                border-radius   : 5px;

                                &.edit-icon-btn {
                                    padding: 5px 8px;
                                }

                                &.delete-icon-btn {

                                    padding: 5px 10px;
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}