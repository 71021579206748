@import "assets/theme/typography.scss";
@import "assets/theme/colors.scss";

.input-group {
    position: relative;

    .search-icon {
        position: absolute;
        top     : 12px;
        left    : 16px;
    }

    .contacts-search-input {
        width           : 100%;
        height          : 48px;
        border-radius   : 7px;
        background-color: #ffffff;
        border          : none;
        outline         : none;
        color           : #707070;
        font-size       : 14px;
        padding         : 15px 15px 15px 56px;
        box-sizing      : border-box;
        box-shadow      : 0 0 4px 0 rgba(51, 51, 51, 0.3);
        font-family     : $sans-reg;
    }
}