@import "assets/theme/sizes.scss";
@import "assets/theme/typography.scss";
@import "assets/theme/colors.scss";

.card {
    padding         : 15px;
    height          : 116px;
    width           : 330px;
    border-radius   : 5px;
    background-color: $white;
    margin-right    : 30px;
    margin-bottom   : 10px;
    cursor          : pointer;

    &:nth-child(3) {
        margin-right: 0;
    }

    &:hover {
        box-shadow: 9px 10px 11px -15px #333;
    }

    .card-body {
        h4 {
            margin        : 0;
            margin-bottom : 11px;
            color         : $dull-black;
            font-family   : $sans-bold;
            font-size     : 14px;
            font-weight   : bold;
            letter-spacing: 0;
            line-height   : 20px;
        }

        p {
            width         : 328px;
            color         : #707070;
            font-family   : $sans-bold;
            font-size     : 14px;
            letter-spacing: 0;
            line-height   : 20px;
        }
    }

}

// CMS Role Details Styles (roleCard.js)

.cmsRoleCard {
    position        : relative;
    background-color: #FFFFFF;
    padding         : 16px 21px 12px 17px;
    border-bottom   : 1px solid #B7B7B7;

    &:last-child {
        border-bottom: 0;
    }

    .cmsRoleCardInfo {
        display        : flex;
        justify-content: flex-start;
        flex-basis     : 80%;
        max-width      : 80%;

        .cmsRoleCardCategory {
            width      : 144px;
            margin-left: 15px;
        }

        .cmsRoleCardGroups {
            width       : 166px;
            margin-left : 29px;
            margin-right: 100px;
        }

        .cmsRoleCardPages {
            width: 336px;
        }

        .cmsRoleCardDetail {
            display       : flex;
            flex-direction: column;

            .cmsRoleName {
                color         : #707070;
                font-family   : $sans-reg;
                font-size     : 12px;
                letter-spacing: 0;
                line-height   : 17px;
                margin-bottom : 10px;
            }

            .cmsRoleValue {
                color         : #333333;
                font-family   : $sans-bold;
                font-size     : 12px;
                font-weight   : bold;
                letter-spacing: 0;
                line-height   : 14px;
                margin-bottom : 10px;
            }

            .cms-btn-link {
                border         : none;
                background     : transparent;
                color          : #03522D;
                font-family    : $sans-reg;
                font-size      : 12px;
                letter-spacing : 0;
                line-height    : 17px;
                display        : flex;
                align-items    : center;
                justify-content: flex-start;
                padding        : 0;
                cursor         : pointer;

                span {
                    margin-right: 5px;
                }
            }

            .cmsBadges {
                display  : flex;
                flex-wrap: wrap;

                .cmsbadge {
                    border-radius   : 16px;
                    background-color: #F2F2F2;
                    padding         : 1px 12px;
                    margin-right    : 4px;
                }

                .cmsRoleValue {
                    color         : #333333;
                    font-family   : $sans-reg;
                    font-size     : 12px;
                    letter-spacing: 0;
                    line-height   : 24px;
                    font-weight   : normal;
                }
            }
        }
    }

    .cmsRoleCardActionButtons {
        position       : absolute;
        right          : 15px;
        top            : 0;
        display        : flex;
        align-items    : center;
        justify-content: center;
        height         : 100%;

        button {
            cursor          : pointer;
            margin-right    : 10px;
            background-color: #F2F2F2;
            color           : $color-green;
            border          : none;
            border-radius   : 5px;

            &.edit-icon-btn {
                padding: 5px 8px;
            }

            &.delete-icon-btn {

                padding: 5px 10px;
            }
        }
    }
}


// Ends