@import "assets/theme/typography.scss";
@import "assets/theme/colors.scss";

.multiselect {
    &.showDropdown {
        .multiselect {
            &__listbtn {
                display: block;
            }

            &__txt {
                background: $blueish-green;
                color     : $white;

                .count {
                    color: $black;
                }
            }
        }
    }

    &.roleCategory {
        .multiselect__txt {
            // width: 175px;
        }
    }

    &.roleGroup {
        .multiselect__txt {
            // width: 155px;
        }
    }

    &__txt {
        background   : transparent;
        color        : $black;
        font-size    : 12px;
        font-family  : $sans-bold;
        padding      : 15px 20px;
        box-sizing   : border-box;
        display      : flex;
        align-items  : center;
        cursor       : pointer;
        border-radius: 7px 7px 0px 0px;

        .count {
            border-radius: 8px;
            padding      : 2px 5px;
            background   : $white;
            color        : $blueish-green;
            margin-right : 5px;
            border       : 1px solid $blueish-green;
        }

        i.icon {
            margin-left: 11.93px;
            font-size  : 15px;
        }
    }

    &__checkbox--list {
        padding : 5px 0;
        position: relative;

        >label {
            display     : block;
            // font-size: 1.1rem;
            // padding: 0.9rem;
            display     : flex;
            align-items : center;
            cursor      : pointer;
            margin      : 0;

            >input {
                position: absolute;
                opacity : 0;
            }
        }

        .optionCheckbox {
            width        : 15px;
            height       : 15px;
            min-width    : 15px;
            display      : inline-block;
            border-radius: 0.25rem;
            border       : 1px solid grey;
            margin-right : 0.75rem;
            text-indent  : -99999px;
            cursor       : pointer;
        }

        input:checked+.optionCheckbox {
            background  : $blueish-green;
            border-color: $blueish-green;
            position    : relative;

            &:after {
                content     : "";
                left        : 4px;
                top         : 0;
                width       : 5px;
                height      : 10px;
                border      : solid $white;
                border-width: 0 2px 2px 0;
                position    : absolute;
                transform   : rotate(40deg);
            }
        }

        .optionTxt {
            span {
                color: $black !important;
            }
        }
    }

    &__listbtn {
        display      : none;
        position     : absolute;
        background   : $white;
        z-index      : 9999;
        width        : 184px;
        box-sizing   : border-box;
        box-shadow   : $whitish-grey -3px 3px 11px 3px;
        font-size    : 12px;
        font-family  : $sans-reg;
        border-radius: 0px 7px 7px;
    }

    &__list {
        margin    : 0;
        padding   : 0;
        list-style: none;
        padding   : 8px 14px 10px;
        max-height: 400px;
        overflow  : auto;
    }

    &__btns {
        padding   : 15px 0 10px;
        text-align: right;
        display   : flex;

        button {
            border-radius : 5px;
            padding       : 0px 18px;
            letter-spacing: 0.8px;
            line-height   : 20px;
            font-size     : 10px;
            cursor        : pointer;
        }

        button:disabled,
        button[disabled] {
            opacity: 0.5;
        }
    }

    &__apply {
        background: $dull-black;
        color     : $white;
        border    : 2px solid transparent;

    }

    &__clear {
        background : $white;
        color      : $dull-black;
        border     : 2px solid $dull-black;
        margin-left: 5px;
    }

    &__searchFilter {
        display         : flex;
        align-items     : baseline;
        background-color: $light-grey;
        padding         : 5px 0;

        .icon {
            font-size: 0.875rem;
            margin   : 5px 8px;
        }

        .inputFilter {
            background-color: $light-grey;
            flex            : 1;
            border          : none;
            outline         : none;
            font-size       : 12px;
            width           : 80%;
        }

        .fa-times-circle {
            cursor: pointer;
        }
    }

    &__dividerwithbtns {
        padding: 0 14px;
    }

    &__isSingle {
        .multiselect__list {
            padding: 0;
        }
    }

    &__item {
        font-size  : 12px;
        padding    : 12px;
        box-sizing : border-box;
        cursor     : pointer;
        display    : flex;
        align-items: center;
    }

    .singleSelect {
        &:hover {
            background-color: $greyish-white;
        }
    }

    .singleSelect.selected {
        background-color: $greyish-white;
    }
}

#divider {
    width     : 100%;
    height    : 2px;
    opacity   : 0.38;
    background: linear-gradient(to right, rgba(220, 220, 220, 0.29), rgba(211, 211, 211, 0.82) 25%, #CACACA 48%, rgba(183, 183, 183, 0.53) 78%, rgba(183, 183, 183, 0.24));
}