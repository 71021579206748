@import "assets/theme/typography.scss";
@import "assets/theme/colors.scss";

.cms-dashboard {
    .cms-header {
        height             : 140px;
        background-image   : url('../../assets/icons/kn-home-theme-31@3x.png');
        background-size    : cover;
        background-repeat  : no-repeat;
        background-position: bottom;
        max-width          : 100%;
        display            : flex;
        align-items        : flex-end;

        h2 {
            margin        : 17px 0 12px 0;
            color         : $white;
            font-family   : $sans-bold;
            font-size     : 20px;
            font-weight   : normal;
            letter-spacing: 0;
            line-height   : 20px;
            text-transform: uppercase;
        }

        .cms-user {
            background   : #fff;
            padding      : 10px;
            display      : flex;
            align-items  : center;
            height       : 75px;
            border-radius: 13px 13px 0 0;

            .welcome-text {
                color         : $dull-black;
                font-family   : $sans-reg;
                font-size     : 16px;
                margin-right  : 5px;
                margin-left   : 10px;
                letter-spacing: 0;
                line-height   : 24px;
            }

            .user-name {
                color         : $dull-black;
                font-family   : $sans-bold;
                font-size     : 16px;
                font-weight   : bold;
                letter-spacing: 0;
                line-height   : 24px;
            }
        }

        .spacer {
            height          : 3px;
            background-color: #F2F2F2;
        }
    }

    .cms-content {
        background-color: $off-white;
        padding-bottom  : 2rem;
        height          : calc(100vh - 140px);
    }
}