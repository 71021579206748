@import 'assets/theme/typography.scss';
@import 'assets/theme/colors.scss';

.container {
    margin: 0 auto;
    width : 1142px;

    .CMSRole-Headline-container {
        position       : relative;
        display        : flex;
        justify-content: space-between;

        .download-btn {
            margin-left: 18px;
            width      : 15px;
            cursor     : pointer;
        }

        .delete-btn {
            width      : 15px;
            margin-left: 10px;
            cursor     : pointer;
        }

        .button-black {
            margin-left: 34px;
        }
    }

    .CMSRole-Headline {
        display    : flex;
        margin     : 15px 0 20px 20px;
        align-items: center;

        h2 {
            margin        : 0 0 0 20px;
            color         : $dull-black;
            font-family   : $sans-bold;
            font-size     : 14px;
            font-weight   : bold;
            letter-spacing: 0.8px;
            line-height   : 24px;
            text-transform: uppercase;
        }

    }

    .mr17 {
        margin-left: 17px;
    }

    .filter-by-text {
        color         : #707070;
        font-family   : $sans-bold;
        font-size     : 12px;
        font-weight   : bold;
        letter-spacing: 0.8px;
        line-height   : 20px;
        display       : flex;
        align-items   : center;

        .filterIcon {
            margin: 0 6px 0 10px;
        }

        i {
            margin-left : 10px;
            margin-right: 6px;
        }
    }

    .v-divider {
        margin: 0 10px 0 10px;
    }

}