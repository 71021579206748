@font-face {
    font-family: "HendersonBCGSans-Regular";
    src: url("HendersonBCGSans-Regular.woff") format("woff");
    font-weight: 400;
  }
  
  @font-face {
    font-family: "HendersonBCGSans-Light";
    src: url("HendersonBCGSans-Light.woff") format("woff");
    font-weight: 300;
  }
  
  @font-face {
    font-family: "HendersonBCGSans-Bold";
    src: url("HendersonBCGSans-Bold.woff") format("woff");
    font-weight: 700;
  }