@import "assets/theme/typography.scss";
@import "assets/theme/colors.scss";

.cms-table-root {
    width          : 100%;
    border         : 1px solid #f2f2f2;
    border-top     : 0;
    border-collapse: collapse;

    .cms-table-head {
        .cms-table-row {
            color           : inherit;
            outline         : 0;
            background-color: #FFFFFF;
            z-index         : 999;

            .cms-table-cell {
                &.cms-table-cell-head {
                    padding       : 18px 18px;
                    color         : #707070;
                    line-height   : 17px;
                    font-family   : $sans-reg;
                    font-size     : 12px;
                    letter-spacing: 0;
                    font-weight   : normal;
                    text-align    : left;
                }
            }
        }
    }


    .cms-table-body {
        .cms-table-row {
            transition      : transform .2s;
            color           : inherit;
            outline         : 0;
            background-color: #FFFFFF;
            vertical-align  : top;

            &:nth-child(odd) {
                background-color: #f2f2f2;
            }

            // &:hover {
            //     transform       : scale(1.0059);
            //     position        : relative;
            //     z-index         : 9;
            //     border          : 1px solid #F2F2F2;
            //     border-radius   : 1px;
            //     background-color: #F2F2F2;
            //     box-shadow      : 0 0 10px 0 rgba(0, 0, 0, 0.2);
            // }

            .cms-table-cell {
                color         : #333333;
                font-family   : $sans-reg;
                font-size     : 12px;
                letter-spacing: 0;
                line-height   : 14px;
                padding       : 18px 18px;

                &:nth-child(1) {
                    width: 150px;
                }

                &:nth-child(2) {
                    width: 262px;
                }

                &:nth-child(3) {
                    width: 145px;
                }

                &:nth-child(4) {
                    width: 71px;
                }

                &:nth-child(5) {
                    width: 57px;
                }

                &:nth-child(6) {
                    width: 246px;
                }

                .cms-form-field {
                    border          : 1px solid #B7B7B7;
                    border-radius   : 4px;
                    background-color: #FFFFFF;
                    padding         : 5px 8px;
        
                    &:disabled {
                        background-color: rgba(239, 239, 239, 0.8) !important;
                        border-color    : rgba(118, 118, 118, 0.5) !important;
                        cursor          : not-allowed;
                    }
                }

                .cms-textarea {
                    box-sizing      : border-box;
                    height          : 66px;
                    width           : 246px;
                    border          : 1px solid #B7B7B7;
                    border-radius   : 4px;
                    background-color: #FFFFFF;
                    padding         : 10px 15px 0 12px;
                    color           : #707070;
                    font-family     : $sans-reg;
                    font-size       : 12px;
                    letter-spacing  : 0;
                    line-height     : 24px;
                }
            }

            
        }
    }
}